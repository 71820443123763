import styled from 'styled-components'
export const LoginSC = styled.div`
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:100vh;
`
export const LoginInputWrapperSC = styled.div`
box-sizing:border-box;
border-radius:3px;
display:flex;
padding:10px;
flex-direction:column;
background:#06a287;
input,button{
  margin:10px 0px;
  padding:15px;
  box-sizing:border-box;
  border-radius:3px;
  outline:none;
  border:none;
}
button{
  color:black;
  background:white;
  font-weight:600;
  transition:300ms;
  &:hover{
    transition:300ms;
    background:black;
    color:white;
  }
}
p{
  color:white;
  text-align:center;
}
`
export const WrapperSC = styled.div`
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      flex-grow: 10;
      background-color: #f5f6f8;
`
export const ContentSC = styled.div`
        flex-grow: 10;
        background-color: #f5f6f8;;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
`
export const MainboardSC = styled.div`
          box-sizing: border-box;
          display: flex;
          flex-flow: row nowrap;
          background-color: #f5f6f8;
          justify-content: flex-end;
          height: calc(100vh - 62px);
          .tasiyici {
            display: flex;
            flex-direction: row;
            flex-grow: 10;
            height: calc(100vh - 61px);
            overflow: auto;
            justify-content: flex-start;
            .sol {
              display: flex;
              flex-grow: 0;
              position: sticky;
              flex-basis: 350px;
              top: 0;
              flex-shrink: 0;
              transition: 500ms;
              &:hover {
                transition: 500ms;
                -webkit-box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
                box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
              }
              .liste {
                width: 100%;
                overflow: auto;
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
                    background-color: #f5f5f5 !important;
                }

                &::-webkit-scrollbar {
                    width: 6px !important;
                    background-color: #f5f5f5 !important;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgb(6, 162, 135) !important;
                }
                .listviewer {
                  width: 100%;
                  box-sizing: border-box;
                  padding: 20px;
                  overflow: auto;
                  z-index: 100;
                  &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
                        background-color: #f5f5f5 !important;
                    }

                    &::-webkit-scrollbar {
                        width: 6px !important;
                        background-color: #f5f5f5 !important;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: rgb(6, 162, 135) !important;
                    }
                  h3 {
                    margin-top: 0;
                    border-bottom: 4px solid rgb(6, 162, 135);;
                    padding-bottom: 2px;
                    display: inline-block;
                  }
                  .aciklayici {
                    float: left;
                    width: 100%;
                    margin: 10px;
                    padding: 0px 20px;
                    box-sizing: border-box;
                    span {
                      text-align: right;
                      line-height: 10px;
                      width: 50%;
                      float: left;
                      &:last-child {
                        &::before {
                          content: "•";
                          font-size: 50px;
                          line-height: 0px;
                          vertical-align: top;
                          color: #f98923;
                        }
                      }
                      &::before {
                        content: "•";
                        font-size: 50px;
                        line-height: 0px;
                        vertical-align: top;
                        color: rgb(6, 162, 135);;
                      }
                    }
                  }

                  ul {
                    margin: 0;
                    padding: 0;
                    float: left;
                    width: 100%;
                    .altcat {
                      border-left: 2px solid #f98923;
                      text-transform: lowercase;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      &:hover {
                        z-index: 10;
                        background: #f98923;
                        color: #f5f6f8;
                        box-shadow: 0px 2px 10px #f98923;
                        text-transform: capitalize;
                      }
                      &:first-letter {
                        text-transform: capitalize;
                      }
                    }
                    li {
                      //text-transform: lowercase;
                      width: 100%;
                      cursor: pointer;
                      list-style: none;
                      padding: 10px 5px;
                      margin: 4px 0px;
                      box-sizing: border-box;
                      border-left: 2px solid rgb(6, 162, 135);;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      &::first-letter {
                        text-transform: capitalize;
                      }
                      span {
                        white-space: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 70%;
                        float: left;
                        //display: contents;
                        font-size: 14px;
                        text-transform: capitalize;
                      }
                      &:hover {
                        z-index: 10;
                        background: rgb(6, 162, 135);;
                        color: #f5f6f8;
                        border-radius: 6px;
                        box-shadow: 0px 2px 10px rgb(6, 162, 135);;
                        //text-transform: lowercase;
                        text-transform: capitalize;
                        text-overflow: ellipsis;
                        &:first-letter {
                          text-transform: capitalize;
                        }
                        svg {
                          color: #f5f6f8;
                        }
                      }
                      
                    }
                  }
                }
                .listviewer2 {
                  width: 100%;
                  float: left;
                  box-sizing: border-box;
                  padding: 20px;
                  height: auto;
                  h3 {
                    margin-top: 0;
                  }
                  ul {
                    margin: 0;
                    padding: 0;
                    li {
                      cursor: pointer;
                      list-style: none;
                      padding: 5px;
                      button {
                        background: none;
                        border: none;
                        img {
                          width: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .sag {
              display: flex;
              flex-grow: 3;
              flex-shrink: 3;
              justify-content: flex-start;
              flex-direction: column;
              overflow: auto;
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
                background-color: #f5f5f5 !important;
              }

              &::-webkit-scrollbar {
                width: 6px !important;
                background-color: #f5f5f5 !important;
              }

              &::-webkit-scrollbar-thumb {
                background-color: rgb(6, 162, 135) !important;
              }
      &:hover {
        transition: 500ms;
        -webkit-box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
        box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
      }
      .baslik {
        padding: 10px;
        box-sizing: border-box;
        h4 {
          display: inline-block;
          border-bottom: 4px solid rgb(6, 162, 135);;
          padding-bottom: 2px;
          margin-bottom: 5px;
        }
      }
      .inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        span{
            float: right;
        }
        h4{
            color:rgb(6, 162, 135);;
      
        }
        .editorName,fieldset {
          //width: 100%;
          flex-grow: 1;
          padding: 20px;
          box-sizing: border-box;
          background-color:white;
          margin:10px 10px;
          border-radius: 6px;
          border:none;
          transition: 300ms;
          &:disabled{
              opacity: 0.5;
            filter: grayscale(100);
            transition: 300ms;
          }
          h4{
              font-size:14px;
              font-weight: normal;
          }
          button{
              padding: 10px 15px;
              background-color: rgb(6, 162, 135);;
              border:none;
              border-radius: 4px;
              color:white;
              box-sizing: border-box;
              transition: 300ms;
              &:last-child{
                  background-color: #f98923;
              }
              margin:0px 20px 0px 0px;
              &:disabled{
                  transition: 300ms;
                  background-color: #f5f6f8;
                  &:hover{
                      background-color: #f5f6f8;
                      box-shadow: none;
                  }
              }
              &:hover{
                  transition: 300ms;
                  background-color: #f98923;
              }
          }
          .checktasiyici{
              width:100%;
              float: left;
              background: #f5f6f8;
              border-left: 2px solid rgb(6, 162, 135);;
              margin: 10px 0px;
              border-radius: 0px 6px 6px 0px;
              padding: 20px;
              box-sizing: border-box;
              transition: 500ms;
              label{
                  margin:0px 10px;
              }
              select{
                  padding: 10px;
                  border-radius: 6px;
                  border-color: rgb(6, 162, 135);;
                  &:focus{
                      outline: none;
                  }
                  &:hover{
                      border-color:#f98923;
                  }
              }
              &:hover {
                  //border: 2px solid rgb(6, 162, 135);;
                  
                  transition: 500ms;
                -webkit-box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
                -moz-box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
                box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
                }
          }
          input {
            caret-color:rgb(6, 162, 135);;
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            margin:10px 0px;
            flex-grow: 1;
            border-radius: 0px 6px 6px 0px;
            border: none;
            transition: 500ms;
            border-left:2px solid rgb(6, 162, 135);;
            background-color: #f5f6f8;
            -webkit-box-shadow: outset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: outset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
            box-shadow: outset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
            &:focus {
              outline: none;
              transition: 500ms;
            -webkit-box-shadow: 0.2rem 0.8rem 1.6rem rgba(6, 162, 136, 0.479);
            -moz-box-shadow: 0.2rem 0.8rem 1.6rem rgba(6, 162, 135,0.479);
            box-shadow: 0.2rem 0.8rem 1.6rem rgba(6, 162, 135, 0.479);
            }
            &:hover {
              outline: none;
              transition: 500ms;
            -webkit-box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
            -moz-box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
            box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
            }
          }
          label {
            width: auto;
            font-size: 14px;
          }
          input[type="checkbox"] {
            width: auto;
            box-shadow: none;
          }
          select {
            width: 100px;
            option {
            }
          }
        }
      }
      .wysiwyg {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
      }
      .editor {
        //float: left;
        flex-grow: 10;
        background-color: #f5f6f8;
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        box-sizing: border-box;
        .inputWrapper {
          float: left;
          width: 100%;
        padding: 20px;
        box-sizing: border-box;
        .inputTextWrapper {
          float: left;
          width: 50%;
          .editorName {
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            input {
              width: 100%;
              padding: 10px;
              box-sizing: border-box;
              &:focus {
                border: 2px solid rgb(6, 162, 135);;
                outline: none;
              }
            }
          }
        }
        .inputSelectWrapper {
          float: left;
          width: 50%;
          .editorName {
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            input {
              width: 100%;
              padding: 5px;
              box-sizing: border-box;
            }
            label {
              input[type="checkbox"] {
                height: 15px;
                width: 15px;
                background-color: #f5f6f8;
                &:hover {
                  background-color: #f98923;
                }
                &:checked {
                  background-color: #2196f3;
                }
              }
              .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #f5f6f8;
              }
              &:hover input ~ .checkmark {
                background-color: white;
              }
            }

            select {
              width: auto;
              margin-left: 10px;
              padding: 10px;
              &:focus {
                border: 1px solid rgb(6, 162, 135);;
                outline: none;
              }
            }
          }
        }
        .buttonWrapper {
          // float: left;
          width: auto;
          padding-left: 10px;
          box-sizing: border-box;
          button {
            padding: 12px 18px;
            float: left;
            margin: 0px 10px;
            border: 1px solid rgba(226, 226, 226, 0);
            background: rgb(6, 162, 135);;
            color: white;
            transition: 300ms;
            &:hover {
              color: black;
              // background: rgb(226, 226, 226);
              border: 1px solid rgb(6, 162, 135);;
              background: none;
              transition: 300ms;
              box-sizing: border-box;
            }
            &:disabled,
            &[disabled] {
              border: 1px solid #999999;
              background-color: #cccccc;
              color: #666666;
              opacity: 0.3;
            }
          }
        }
      }
    }
             }
          }

          .listviewer2 {
            width: 100%;
            float: left;
            box-sizing: border-box;
            padding: 20px;
            height: auto;
            h3 {
              margin-top: 0;
            }
            ul {
              margin: 0;
              padding: 0;
              li {
                cursor: pointer;
                list-style: none;
                padding: 5px;
                button {
                  background: none;
                  border: none;
                  img {
                    width: 10px;
                  }
                }
              }
            }
          }
         
`