import styled from 'styled-components'
export const LoginSC = styled.div`
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:100vh;
`
export const LoginInputWrapperSC = styled.div`
box-sizing:border-box;
border-radius:3px;
display:flex;
padding:10px;
flex-direction:column;
background:#06a287;
input,button{
  margin:10px 0px;
  padding:15px;
  box-sizing:border-box;
  border-radius:3px;
  outline:none;
  border:none;
}
button{
  color:black;
  background:white;
  font-weight:600;
  transition:300ms;
  &:hover{
    transition:300ms;
    background:black;
    color:white;
  }
}
p{
  color:white;
  text-align:center;
}
`