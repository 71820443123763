import React, { createContext, useReducer } from 'react'
import { node } from 'prop-types'

// load reducers
import themeReducer from '../reducer/themeReducer'

// craete initial payload
const theme = 'light'

// create context
export const ThemeContext = createContext(theme);

// create Store
function Store({ children }) {
  const [state, dispatch] = useReducer(themeReducer, theme);
  const value = { state, dispatch };
  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  )
}

Store.defaultProps = {
  children: null
}

Store.propTypes = {
  children: node
}

export default Store;