import styled from 'styled-components'

export const HeaderSC = styled.header`
box-sizing: border-box;
display: flex;
flex-flow: row nowrap;
justify-content: space-between;
z-index: 100;
top: 0;
position: -webkit-sticky;
position: sticky;
.logo {
  padding: 20px;
  box-sizing: border-box;
  text-transform: capitalize;
  sub {
    font-size: 8px;
  }
}
ul {
  margin-right: 20px;
  box-sizing: border-box;
  button{
    border:none;
    background:none;
    font-size: 0.9em;
    margin: 5px;
    font-family:'Montserrat';
    color:#06a287;
    cursor:pointer;
    &:hover {
        color:#f98923;
      }
  }
  li {
    list-style: none;
    text-decoration: none;
    float: left;
    margin: 5px;
    color: #06a287;
    a {
      text-decoration: none;
      font-size: 0.9em;
      color:#06a287;
      &:hover {
        color:#f98923;
      }
    }
  }
}
hr {
  float: left;
  width: 100%;
}
`