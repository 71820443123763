import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoIosAdd, IoMdRefreshCircle, IoMdSave, IoMdChatbubbles } from "react-icons/io";
import { toast } from 'react-toastify';
import Modal from "../../components/Modal";
import List from './list'
import { Sag } from './styles.js'
import { _platformlistesi, _platformGuncelle, _platformDetay, _platformSil, _platformKaydet } from './services'

const Platforms = function (props) {
    const [secim, setSecim] = useState(false);
    const [dataBaslik, setDataBaslik] = useState(false);
    const [show, setShow] = useState(false);
    useEffect(() => {
        _platformlistesi()
            .then(list => {
                setDataBaslik(list.data.data)
            })
    }, [props.apiUrl])
    function showModal(e) {
        setShow(!show)
    };
    function showModalAndClear(e) {
        setShow(!show)
        setSecim(false)
    };
    function getir() {
        _platformlistesi()
            .then(list => {
                setDataBaslik(list.data.data)
            })
    }
    function _contentUpdateSliderItem() {

        const { id, name, trackedvalue } = secim;
        _platformGuncelle({ name, trackedvalue }, id)
            .then(function (res) {
                if (!res.data.error) {
                    toast.success('Güncelleme başarılı.')
                    getir();
                } else {
                    toast.error('Güncelleme yapılamadı.')
                }
            });

    }
    function _contentUpdateSliderItemClear() {
        setSecim(false)
    }
    function _handleSec(e) {
        let secimProp = { ...secim }
        let alan = `${e.target.name}`;
        secimProp[alan] = e.target.value;
        setSecim(secimProp)
    }
    function _clickEvent(e) {
        _platformDetay(e.id)
            .then((gelen) => {
                setSecim(gelen.data.data)
                showModal(e);
            });
    }
    function _contentSave() {
        _platformKaydet({
            name: secim.name,
            trackedvalue: secim.trackedvalue,
        })
            .then(function (res) {
                if (!res.data.error) {
                    toast.success('Kaydetme başarılı.')
                    getir();
                } else {
                    toast.error('Kaydetme yapılamadı.')
                }
            });


    }
    function _clickDel(x) {
        _platformSil(x)
            .then(function (res) {
                if (!res.data.error) {
                    toast.success('Silme başarılı.')
                    getir();
                } else {
                    toast.error('Silme yapılamadı.')
                }
            });
    }
    return <div className="tasiyici">
        <Modal baslik={"Platform Ekle / Düzenle"} onClose={showModal} show={show}>
            <div className="inputs">
                <div className="editorName">
                    <input type="textbox" placeholder="Sağlayıcı Adı Örn:Google" name="name" value={secim.name || ""} onChange={(e) => _handleSec(e)} />
                    <input type="textbox" placeholder="Takip Kodu Örn:UA-00000000-00" name="trackedvalue" value={secim.trackedvalue || ""} onChange={(e) => _handleSec(e)} />
                </div>
                <div className="editorName">
                    <button className="buttons" disabled={(secim && !secim.id) ? false : true} onClick={() => _contentSave()}><IoMdSave />Kaydet</button>
                    <button className="buttons" disabled={secim.id ? false : true} onClick={() => _contentUpdateSliderItem()}><IoMdRefreshCircle />Güncelle</button>
                    <button className="buttons" disabled={secim ? false : true} onClick={() => _contentUpdateSliderItemClear()}> <IoIosAdd />Yeni Oluştur</button>
                </div>
            </div>
        </Modal>
        <div className="sol">
            <List
                baslik={"İletişim Platformları"}
                ekle={showModalAndClear}
                sil={_clickDel}
                edit={_clickEvent}
                data={dataBaslik}
            />
        </div>
        <Sag>
            <p>Sitedeki ziyaretçileri mesajlaşmayı sağlıcak uygulamaları yönetebildiğniz kısım.
         <i>"Ekle"</i> butonu ile yeni bir platform ekleyebilirsiniz.</p>
            <h5>Örneğin:</h5>
            <ul>
                <li>Whatsapp</li>
                <li>Hangouts</li>
            </ul>
        </Sag>
    </div>
}
Platforms.propTypes = {
    apiUrl: PropTypes.string.isRequired
};

export default {
    routeProps: {
        path: '/platforms',
        component: Platforms,
    },
    name: 'Platforms',
    icon: <IoMdChatbubbles />,
    hideMenu: false,
};