import styled from 'styled-components'

export const FloatDiv = styled.div`
display: flex;
  flex-direction: column;
  flex-basis:50%;
  padding:5px;
  box-sizing:border-box;
`
export const FloatInput = styled.input`
  width: 100%;
  height: 56px;
  padding: 14px 16px 0 10px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  box-sizing:border-box;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 16px;
`
export const FloatSelect = styled.select`
  width: 100%;
  height: 56px;
  padding: 14px 16px 0 10px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  box-sizing:border-box;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 16px;
`
export const FloatLabel = styled.label`
    position: absolute;
    font-size: 16px;
    /* font-family: Arial, Helvetica, sans-serif; */
    padding: 0 12px;
    box-sizing:border-box;
    color: #999;
    pointer-events: none;
    transform-origin: top left;
    transition: all 0.2s ease-out;
    transform: ${props => props.active ? 'translate(0, 12px) scale(0.75)':'translate(0, 20px) scale(1)'};
    `
    export const FloatCheckBox = styled.input`
    width: 70%;
    height: 16px;
    padding: 14px 16px 0 10px;
    margin:10px 0px;
    outline: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    box-sizing:border-box;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-size: 16px;
  `