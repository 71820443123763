import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import '../../styles/react-draft-wysiwyg.css'
import htmlToDraft from 'html-to-draftjs';
import ListViewer from './Liste';
import { FaQuestionCircle } from 'react-icons/fa';
import { MdGolfCourse } from "react-icons/md";
import StepZilla from "react-stepzilla";
import { toolbarDefine } from '../../utils/editorToolbar'
import { PlaceHolderTr, PlaceHolderCz, PlaceHolderDe, PlaceHolderEn, PlaceHolderRu } from './editorInitial/placeHolder'
import { initialStateCz, initialStateDe, initialStateEn, initialStateRu, initialStateTr } from './editorInitial/';
import { toast } from 'react-toastify'
import ScoreBoard from '../../components/scoreboard'
import '../../styles/Draft.css'
import { _hotellistesi, _scorecardKaydet, _sahalistesi, _sahaSil, _sahaKaydet, _sahaDetay, _sahaGuncelle } from './services';
function Saha(props) {
  const [editorStateTr, setEditorStateTr] = useState(initialStateTr);
  const [editorStateEn, setEditorStateEn] = useState(initialStateEn);
  const [editorStateRu, setEditorStateRu] = useState(initialStateRu);
  const [editorStateDe, setEditorStateDe] = useState(initialStateDe);
  const [editorStateCz, setEditorStateCz] = useState(initialStateCz);
  const [secim, setSecim] = useState(false);
  const [liste, setListe] = useState(false);
  const [hotellist, setHotelList] = useState(false);
  const [scorecardlist, setScorecardlist] = useState([]);

  useEffect(() => {
    _sahalistesi()
      .then((gelen) => {
        setListe(gelen.data.data)
      });
    _hotellistesi()
      .then((gelen) => {
        setHotelList(gelen.data.data)
      });
  }, [])
  function scorecardadd(e) {
    console.log(e)
    // setScorecardlist([...scorecardlist, e])
    // console.log(scorecardlist)
    if (secim.id) {
      // fetch(props.apiUrl + '/scorecard', {
      //   method: 'POST',
      //   headers: {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     name: e.tablename,
      //     saha_id: secim.id,
      //     columnNames: e.data
      //   })
      // })
      //   .then(response => response.json())
      console.log("secim");
      _scorecardKaydet({
        name: e.tablename,
        saha_id: secim.id,
        columnNames: e.data
      })
        .then(function (data) {
          if (!data.data.error) {
            toast.success('Card eklendi')

            _listeGuncelle();
            _clickEvent(secim);
          } else {
            toast.error('Card ekleme başarısız.')
          }
        });
    } else {
      console.log("else")
      setScorecardlist([...scorecardlist, e])
      console.log(scorecardlist)
    }


  }
  function _yeniSayfa() {
    setSecim(false)
  }
  function _listeGuncelle() {
    _sahalistesi()
      .then((gelen) => {
        setListe(gelen.data.data)
      });
  }
  function _clickDel(x) {
    _sahaSil(x.id)
      .then(function (data) {
        // self.setState({ data })
        if (!data.data.error) {
          toast.success('Saha silindi')
          _listeGuncelle();
          _yeniSayfa();
        } else {
          toast.error('Saha silme başarısız.')
        }
      });
  }
  function _contentSave() {
    let HOTELID = secim.hotel_id
    _sahaKaydet({
      name: secim.name,
      tr: draftToHtml(convertToRaw(editorStateTr.getCurrentContent())),
      en: draftToHtml(convertToRaw(editorStateEn.getCurrentContent())),
      ru: draftToHtml(convertToRaw(editorStateRu.getCurrentContent())),
      de: draftToHtml(convertToRaw(editorStateDe.getCurrentContent())),
      cz: draftToHtml(convertToRaw(editorStateCz.getCurrentContent())),
      map: secim.map,
      list_sira_no: secim.list_sira_no,
      hotel_id: HOTELID,
      scorecardlist: scorecardlist
    })
      .then(function (data) {
        if (!data.data.error) {
          toast.success('Saha eklendi')
          _listeGuncelle();
          _yeniSayfa();
        } else {
          toast.error('Saha ekleme başarısız.')
        }
      });


  }
  function _stateConverter(data) {
    const blocksFromHtml = htmlToDraft(data);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  function _clickEvent(e) {
    _sahaDetay(e.id)
      .then((gelen) => {
        const editorStateTr = _stateConverter(gelen.data.data.aciklama[0].tr || PlaceHolderTr);
        const editorStateEn = _stateConverter(gelen.data.data.aciklama[0].en || PlaceHolderEn);
        const editorStateRu = _stateConverter(gelen.data.data.aciklama[0].ru || PlaceHolderRu);
        const editorStateDe = _stateConverter(gelen.data.data.aciklama[0].de || PlaceHolderDe);
        const editorStateCz = _stateConverter(gelen.data.data.aciklama[0].cz || PlaceHolderCz);
        setEditorStateTr(editorStateTr);
        setEditorStateEn(editorStateEn);
        setEditorStateRu(editorStateRu);
        setEditorStateDe(editorStateDe);
        setEditorStateCz(editorStateCz);
        setSecim({ ...gelen.data.data, hotel_id: gelen.data.data.hotel.length > 0 ? gelen.data.data.hotel[0].id : false })
        setScorecardlist(gelen.data.data.scorecard)
      })
  }
  function onEditorStateChangeEn(editorStateEn) {
    setEditorStateEn(editorStateEn);
  }
  function onEditorStateChangeTr(editorStateTr) {
    setEditorStateTr(editorStateTr);
  }
  function onEditorStateChangeRu(editorStateRu) {
    setEditorStateRu(editorStateRu);
  }
  function onEditorStateChangeDe(editorStateDe) {
    setEditorStateDe(editorStateDe);
  }
  function onEditorStateChangeCz(editorStateCz) {
    setEditorStateCz(editorStateCz);
  }
  function _inputChange(e) {
    let secimProp = { ...secim }
    let alan = `${e.target.name}`;
    secimProp[alan] = e.target.value;
    setSecim(secimProp);
  }

  function _contentUpdate() {
    const { id, name, map, hotel_id, list_sira_no } = secim;
    _sahaGuncelle({
      name: name,
      tr: draftToHtml(convertToRaw(editorStateTr.getCurrentContent())),
      en: draftToHtml(convertToRaw(editorStateEn.getCurrentContent())),
      ru: draftToHtml(convertToRaw(editorStateRu.getCurrentContent())),
      de: draftToHtml(convertToRaw(editorStateDe.getCurrentContent())),
      cz: draftToHtml(convertToRaw(editorStateCz.getCurrentContent())),
      map: map,
      list_sira_no: list_sira_no,
      hotel_id: hotel_id,
      aciklama_id: secim.aciklama[0].id
    }, id
    )
      .then(function (data) {
        // self.setState({ data })
        if (!data.error) {
          toast.success('Saha Güncellendi')
          _listeGuncelle();
          _yeniSayfa();
        } else {
          toast.error('Saha güncelleme başarısız.')
        }
      });

  }

  const steps = [
    {
      name: 'Ad', component: <div className="inputs"><div className="editorName">
        <label>Saha Adı</label>
        <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
        <input type="text" required value={secim.name || ""} name="name" placeholder="e.g: Nick Faldo" onChange={(e) => _inputChange(e)} />
        <label>Sıra No</label>
        <span title="Frontend'de saha listesindeki sıralamayı ayarlar"><FaQuestionCircle /></span>
        <input type="text" required value={secim.list_sira_no || ""} name="list_sira_no" placeholder="e.g: 1" onChange={(e) => _inputChange(e)} />
        <div className="checktasiyici">
          <label>Hotel Seç</label>
          <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
          <select onChange={(e) => _inputChange(e)} value={secim.hotel_id || ""} name="hotel_id">
            <option value={false}>Seçilmedi</option>
            {hotellist ? hotellist.map((x, i) => {
              return <option key={i} value={x.id}>{x.name}</option>
            }) : false}
          </select>
        </div>
        <label>Konum</label>
        <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
        <input type="text" required value={secim.map || ""} name="map" placeholder="e.g: 35.5542" onChange={(e) => _inputChange(e)} />

      </div></div>
    },
    {
      name: 'İçerik', component: <div className="wysiwyg">
        <Editor
          editorState={editorStateEn}
          onEditorStateChange={onEditorStateChangeEn}
          toolbar={toolbarDefine}
        />
        <Editor
          editorState={editorStateRu}
          onEditorStateChange={onEditorStateChangeRu}
          toolbar={toolbarDefine}
        />
        <Editor
          editorState={editorStateDe}
          onEditorStateChange={onEditorStateChangeDe}
          toolbar={toolbarDefine}
        />
        <Editor
          editorState={editorStateTr}
          onEditorStateChange={onEditorStateChangeTr}
          toolbar={toolbarDefine}
        />
        <Editor
          editorState={editorStateCz}
          onEditorStateChange={onEditorStateChangeCz}
          toolbar={toolbarDefine}
        />
      </div>
    },
    {
      name: 'Score Boards',
      component: <ScoreBoard
        addScorecard={scorecardadd}
        apiUrl={props.apiUrl}
        scorecardlist={scorecardlist}
        id={secim.id}
        listeGuncelle={_listeGuncelle}
      />
    },
    {
      name: 'Bitir', component: <div className="inputs"> <div className="editorName">
        <button disabled={secim.id ? false : true} onClick={_contentUpdate}>Güncelle</button>
        <button disabled={(secim && !secim.id) ? false : true} onClick={_contentSave}>Kaydet</button>
        <button disabled={secim ? false : true} onClick={_yeniSayfa}>Temizle</button>
      </div></div>
    }
  ];
  return <div className="tasiyici">
    <div className="sol">
      <div className="liste">
        {liste ? <ListViewer
          title="Sahalar"
          ekle={_yeniSayfa}
          liste={liste}
          clickDel={_clickDel}
          clickEvent={_clickEvent} /> : <div className="loading">"Yükleniyor..."</div>}
      </div>
    </div>
    <div className="sag">
      <div className="baslik">
        <h4>Saha İçeriği</h4>
      </div>
      <StepZilla
        steps={steps}
        preventEnterSubmission={true}
        nextButtonCls="ileri"
        backButtonCls='geri'
      />
    </div>
  </div>
}


export default {
  routeProps: {
    path: '/saha',
    component: Saha
  },
  name: 'Golf Sahaları',
  icon: <MdGolfCourse />,
  hideMenu: false,
}
