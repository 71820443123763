import React, {useContext} from 'react';

import { FaHome } from 'react-icons/fa';
import { UserContext } from '../../stores/userContextV2'
import {logoutUser,loginUser} from '../../actions/userActions'
function Home() {
    const {userState, userDispatch} = useContext(UserContext);
    console.log(UserContext)
    return (
        <div className="tasiyici">
            <div className="sag">
                <div className="inputs">
                    <div className="editorName">
                        {/* <div className="baslik"><h2>{userState}</h2></div> */}
                        {userState?<button onClick={()=>{localStorage.removeItem("user");userDispatch(logoutUser())}}>Oturum Kapat</button>:<button onClick={()=>userDispatch(loginUser())}>Oturum Aç</button>}
                        {userState? <React.Fragment><div className="baslik"><h2>Web Sayfası Yönetim Paneli</h2></div>
                        <p>Sol kısımdaki açılabilir panelden ilgili bölümlere tıklayarak web sayfanız üzerinde değişiklik yapabilirsiniz.</p>
                        <p>Yönetim Panelinin özellikleri:</p>
                        <ul>
                            <li>Sayfa oluşturma,silme ve düzenleme</li>
                            {/* <li>Slider üzerindeki başlık ve maddeleri düzenleme</li>
                            <li>Medical Specials bölümündeki başlıkları düzenleme,başlık altındaki resimli içerikleri değiştirme,silme,link verme işlemi</li>
                            <li>Why Choose Unimed Alanına madde ekleme çıkarma silme </li>
                            <li>How Its maddeleri ekleme,silme, düzenleme</li> */}
                            <li>Analytics bölümünde google gibi site istatistiği tutan servislerin aktivasyonu sağlanabilir.</li>
                            <li>Platforms kısmı sayfanın altına whatsapp gibi chat uygulamaları eklemeyi sağlar.</li>
                        </ul></React.Fragment>:<p>Oturum Açın</p>}
                        
                    </div>

                </div>

            </div>
        </div>


    )
}

export default {
    routeProps: {
        path: '/',
        exact:true,
        component: Home
    },
    name: 'Home',
    icon:<FaHome/>,
    headerLocale:true,
    hideMenu:true,
}