import React, { useContext, useState } from 'react';
import { UserContext } from '../../stores/userContextV2'
import { loginUser, logoutUser } from '../../actions/userActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginSC, LoginInputWrapperSC } from './styles';
import service from './services'
export default (props) => {
    const { userDispatch } = useContext(UserContext);
    const [username, setUsername] = useState(false)
    const [password, setPassword] = useState(false)
    function login() {
        service._login(username, password)
            .then(function (res) {
                if (res.data.login) {
                    localStorage.setItem("user", JSON.stringify(res.data));
                    userDispatch(loginUser())
                    toast.success('Oturum açıldı')
                } else {
                    localStorage.removeItem("user");
                    userDispatch(logoutUser())
                    toast.error('Kullanıcı adı ve şifrenizi kontrol ediniz.')
                }
            });
    }
    return <LoginSC>
        <ToastContainer />
        <LoginInputWrapperSC>
            <p>Yönetim Paneli</p>
            <input type="text"
                value={username || ""}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input type="password"
                value={password || ""}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={() => login()}>Oturum Aç</button>
        </LoginInputWrapperSC>
    </LoginSC>
}