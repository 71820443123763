import React from 'react';
import { MdDeleteForever, MdModeEdit } from "react-icons/md";
const ListeView = (props) => {
    const { liste, clickEvent, clickDel, title } = props;
    return (
        <div className="listviewer">
            <h3>{title}</h3>
            <div className="aciklayici">
            <span> Üst Menü</span>
            <span> Alt Menü</span>
            </div>

            {liste ? <ul>
                {liste.map(function (x, i) {
                    if(x.MainSub){
                        return <li className="altcat" key={i}><span>{x.nameen}</span><button className="listbutton" onClick={()=>clickDel(x)}><MdDeleteForever/></button><button className="listbutton"  onClick={() =>clickEvent(x)}><MdModeEdit/></button></li>
                    }
                    else{return <li key={i} ><span>{x.nameen}</span><button className="listbutton" onClick={()=>clickDel(x)}><MdDeleteForever/></button><button className="listbutton"  onClick={() => clickEvent(x)}><MdModeEdit/></button></li>}
                })}
            </ul>
                : "Veri Yok"}

        </div>
    )
}
export default ListeView;
