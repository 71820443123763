import styled from 'styled-components'

export const ProgressSC = styled.div`
    background-color: #d8d8d8;
	border-radius: 4px;
	position: relative;
	margin: 15px 0;
	height: 30px;
	width: 300px;
`;

export const ProgressDoneSC = styled.div`
   	background: linear-gradient(to left, #f98923, #06a287);
	box-shadow: 0 3px 3px -5px #f98923, 0 2px 5px #06a287;
	border-radius: 4px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	/* transition: 1s ease 0.3s; */
`;