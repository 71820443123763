import Analytics from './Analytics';
import EditorWys2 from './EditorWys2';
import Home from './Home';
// import HowIts from './HowIts';
// import MedicalSpecials from './MedicalSpecials';
import MetaTags from './MetaTags';
// import PageCreate from './PageCreate';
import Platforms from './Platforms';
import Test from './Test';
import Version from './Version';
// import WhyChoose from './WhyChoose';
import Gallery from './Gallery'
// import Hotel from './Hotel'
import HotelV2 from './HotelV2'
import Paket from './Paket'
import Saha from './Saha'
import MultiSlider from './MultiSlider'
import Mail from './Mail'
import Push from './PushNotification'
import Talep from './Talep'
export default [
    Paket,
    HotelV2,
    Saha,
    Gallery,
    MultiSlider,
    // EditorWys2,
    Talep,
    Analytics,
    Home,
    // HowIts,
    // MedicalSpecials,
    // MetaTags,
    // PageCreate,
    Platforms,
    Test,
    Version,
    Mail,
    Push
    // WhyChoose,
];