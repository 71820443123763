import React, { useState } from 'react';

import List from './list'
import Modal from "../../components/Modal";
import Table from './table'
import { toast } from 'react-toastify'
import { _scorecardDetay, _scorecardSil, _scorecardRowGuncelle, _scorecardRowKaydet, _scorecardColumnSil, _scorecardColumnGuncelle, _scorecardColumnKaydet } from './services'
export default (props) => {
    const [columnNames, setColumnNames] = useState([]);
    function Randomizer() {
        return Math.floor(Math.random() * 999999999999999)
    }
    const [rowData, setRowData] = useState([]);
    const [inputName, setInputName] = useState([]);
    const [inputColor, setInputColor] = useState([]);
    const [inputTextColor, setInputTextColor] = useState([]);
    const [tableName, setTableName] = useState();
    const [tableId, setTableId] = useState(false);
    const [columnId, setColumnId] = useState(false);
    const [show, setShow] = useState(false);
    function showModal(e) {
        setShow(!show)
    };
    function _addColumnName(e) {
        if (tableId) {
            _scorecardColumnKaydet({
                name: inputName,
                color: inputColor,
                text_color: inputTextColor,
                sira_no: columnNames.length,
                scorecard_id: tableId,
            })
                .then(function (data) {
                    console.log(data)
                    setColumnId(data.data.column_id)
                    if (!data.data.error) {
                        toast.success('Sutun eklendi')
                    } else {
                        toast.error('Sutun ekleme başarısız.')
                    }
                });
            if (columnNames.some((f) => { return f.rows !== undefined })) {
                let dat = columnNames[0].rows;
                let deger = dat.map((y, k) => {
                    return { uid: Randomizer(), value: "", set_bold: false }
                })
                let arr = [...columnNames, { ...e, rows: deger }]
                setColumnNames(arr)
            } else {
                setColumnNames([{ name: inputName, color: inputColor, text_color: inputTextColor, sira_no: columnNames.length, rows: [] }])
            }
        } else {
            if (columnNames.some((f) => { return f.rows !== undefined })) {
                let dat = columnNames[0].rows;
                let deger = dat.map((y, k) => {
                    return { uid: Randomizer(), value: "", set_bold: false }
                })
                let arr = [...columnNames, { ...e, rows: deger }]
                setColumnNames(arr)
            } else {
                setColumnNames([{ name: inputName, color: inputColor, text_color: inputTextColor, sira_no: columnNames.length, rows: [] }])
            }
        }


    }
    function _deleteColumnName(e, x) {
        let arr = columnNames;
        let deleteArr = arr.filter(function (value, index, arr) { return index !== e; })
        setColumnNames(deleteArr)
        if (x.id) {
            _scorecardColumnSil(x.id)
                .then(function (data) {
                    // self.setState({ data })
                    if (!data.data.error) {
                        toast.success('Sütun silindi')
                    } else {
                        toast.error('Sütun silme başarısız.')
                    }
                });
        }

    }
    function _rowAddValue(firstindex, secondindex, value, y) {
        let data = columnNames;
        data[firstindex].rows[secondindex].value = value;
        data[firstindex].rows[secondindex].sira_no = secondindex;
        data[firstindex].rows[secondindex].set_bold = false;
        console.log(columnNames)
        setColumnNames([...data])
    }
    function _rowAddBold(firstindex, secondindex, value, y) {
        console.log(value)
        let data = columnNames;
        data[firstindex].rows[secondindex].set_bold = value;
        console.log(columnNames)
        setColumnNames([...data])
    }
    function remoteRowBoldChange(value, y, column_id) {
        if (y.id) {
            _scorecardRowGuncelle({
                uid: y.uid,
                value: y.value,
                sira_no: y.sira_no,
                set_bold: value
            }, y.id)
                .then(function (data) {
                    if (!data.data.error) {
                        toast.success('Satır güncellendi')
                    } else {
                        toast.error('Satır güncelleme başarısız.')
                    }
                });
        } else if (tableId) {
            _scorecardRowKaydet({
                uid: y.uid,
                value: y.value,
                sira_no: y.sira_no,
                cardcolumn_id: column_id || columnId,
                set_bold: value,
            })
                .then(function (data) {
                    // self.setState({ data })
                    if (!data.data.error) {
                        toast.success('Satır Eklendi')
                    } else {
                        toast.error('Satır ekleme başarısız.')
                    }
                });
        }
    }
    function remoteRowChange(value, y, column_id) {
        if (y.id) {
            _scorecardRowGuncelle({
                uid: y.uid,
                value: value,
                sira_no: y.sira_no,
                set_bold: y.set_bold
            }, y.id)
                .then(function (data) {
                    if (!data.data.error) {
                        toast.success('Satır güncellendi')
                    } else {
                        toast.error('Satır güncelleme başarısız.')
                    }
                });
        } else if (tableId) {
            console.log(y)
            _scorecardRowKaydet({
                uid: y.uid,
                value: y.value,
                sira_no: y.sira_no,
                set_bold: y.set_bold,
                cardcolumn_id: column_id || columnId
            })
                .then(function (data) {
                    // self.setState({ data })
                    if (!data.data.error) {
                        toast.success('Satır Eklendi')
                    } else {
                        toast.error('Satır ekleme başarısız.')
                    }
                });
        }
    }
    function _columnChanger(firstindex, value) {
        let data = columnNames;
        data[firstindex].name = value;
        console.log(columnNames)
        setColumnNames([...data])
    }
    function _columnRemoteChanger(value, x) {
        if (x.id) {
            _scorecardColumnGuncelle(
                {
                    name: x.name,
                    color: x.color,
                    text_color: x.text_color,
                    sira_no: x.sira_no,
                }, x.id
            )
                .then(function (data) {
                    // self.setState({ data })
                    if (!data.data.error) {
                        toast.success('Sütun güncellendi')
                    } else {
                        toast.error('Sütun güncelleme başarısız.')
                    }
                });
        }
    }
    function _addRow() {
        let arr = columnNames.map((x, i) => {
            let data = x;

            let newRow = data.rows.push({
                uid: Randomizer(),
                value:"",
                sira_no: data.rows.length,
            });
            console.log(data)
            return data;
        })

        setColumnNames(arr)
    }
    function edittable(e) {
        _scorecardDetay(e)
            .then((gelen) => {
                console.log(gelen)
                setTableId(gelen.data.data.id)
                setTableName(gelen.data.data.name)
                setColumnNames(gelen.data.data.cardcolumn)

            })
        setShow(true)
    }
    function silScorecard(e) {
        _scorecardSil(e)
            .then(response => console.log(response))
    }
    return <>
        <Modal baslik={"Score Card Ekle / Düzenle"} onClose={showModal} show={show}>
            <div>
                <label>Score Card Adı</label>
                <input type="textbox" value={tableName} onChange={(e) => setTableName(e.target.value)} />
            </div>
            <div>
                <h4>Kolon ekle</h4>
                <input placeholder="Kolon Adı" onChange={(e) => setInputName(e.target.value)} />
                <input placeholder="Kolon Rengi" onChange={(e) => setInputColor(e.target.value)} />
                <input placeholder="Kolon Yazı Rengi" onChange={(e) => setInputTextColor(e.target.value)} />
                <button
                    onClick={() => {
                        _addColumnName({ name: inputName, color: inputColor, text_color: inputTextColor, sira_no: columnNames.length, rows: [] });
                        // edittable(tableId)
                    }
                    }
                >
                    Ekle
                        </button>
            </div>
            <Table
                addValue={_rowAddValue}
                addBold={_rowAddBold}
                changeColumn={_columnChanger}
                rowRemote={remoteRowChange}
                rowBoldChange={remoteRowBoldChange}
                changeRemoteColumn={_columnRemoteChanger}
                deleteColumn={_deleteColumnName}
                columnNames={columnNames}
            />
            <div>
                <button onClick={() => _addRow()}>Satır Ekle</button>
            </div>
            <button onClick={() => {
                props.addScorecard({ tablename: tableName, data: columnNames })
                setShow(false);
            }}
            >Kaydet</button>
        </Modal>
        {props.scorecardlist ? <List
            scorecardlist={props.scorecardlist}
            apiUrl={props.apiUrl}
            id={props.id}
            edit={edittable}
            sil={silScorecard}
        /> : false}
        <button onClick={() => { showModal(true); setColumnNames([]); setTableName("") }}>Ekle</button>
    </>
}