import React, { useEffect, useState } from 'react';
import { UrlContext } from '../../../stores/url'
import { Wrapper, UL, Li } from './styles'
import { _images, _silResim,_imagesSetCover } from '../services'
import { toast } from 'react-toastify';
const API_URL = UrlContext._currentValue;
export default (props) => {
    const [imageList, setImageList] = useState(false);
    useEffect(() => {
        _images(props.gallery_id).then(
            (res) => {
                console.log(res)
                setImageList(res.data.data)
            }
        )
    }, [props.gallery_id])
    function resimSil(x) {
        _silResim(x).then((res) => {
            _images(props.gallery_id).then(
                (res) => {
                    console.log(res)
                    setImageList(res.data.data)
                }
            )
        })
    }
    function _setEt(x,value){
        _imagesSetCover({set_cover:value},x).then((res) => {
            _images(props.gallery_id).then(
                (res) => {
                    console.log(res)
                    toast.success('Set edildi')
                    setImageList(res.data.data)
                }
            )})
    }
    function _setSira(x,value){
        _imagesSetCover({sira_no:value},x).then((res) => {
            _images(props.gallery_id).then(
                (res) => {
                    console.log(res)
                    toast.success('Güncellendi')
                    setImageList(res.data.data)
                }
            )})
    }
    return imageList ? <Wrapper>
        {imageList&&"Yüklü Olanlar"}
        <UL>{imageList.sort((c, d) => c.sira_no - d.sira_no).map((x, y) => {
        return <Li key={y}>
            <img
                src={API_URL + '/public/uploads/' + x.name}
                alt={x.name} />
            <span>{x.name}
            <input type="textbox" value={x.sira_no ||""} placeholder="Sırası" onChange={(e) => _setSira(x.id,e.target.value)}/>
            <input type="checkbox" defaultChecked={x.set_cover&&"checked"} onClick={(e) =>_setEt(x.id,!x.set_cover)}/>
            <button onClick={() => resimSil(x.id)}>Sil</button></span>
        </Li>
    })}
    </UL></Wrapper> : false
}