import React, { useEffect, useState } from 'react';
import Modal from "../../components/Modal";
import Lister from '../../components/List/multisliderlist'
import { MdPictureInPictureAlt } from "react-icons/md";
import { IoIosAdd, IoMdRefreshCircle, IoMdSave } from "react-icons/io";
import Multistep from 'react-multistep';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GalleryItem from './galleryItem'
import { _multisliderlistesi, _multisliderGuncelle, _multisliderDetay, _multisliderKaydet, _upload, _multisliderSil } from './services'
const MultiSlider = function (props) {
    const [secim, setSecim] = useState(false);
    const [dataBaslik, setDataBaslik] = useState(false);
    const [show, setShow] = useState(false);
    useEffect(() => {
        _multisliderlistesi()
            .then(list => {
                if (!list.data.error) {
                    setDataBaslik(list.data.data)
                } else {
                    toast.error('Sliderlar getirilemedi');
                }
            })

    }, [])
    function showModal(e) {
        setShow(!show)
    };
    function showModalAndClear(e) {
        setSecim(false);
        setShow(!show);
    };
    function getir() {
        _multisliderlistesi()
            .then(list => {
                if (!list.data.error) {
                    setDataBaslik(list.data.data)
                } else {
                    toast.error('Sliderlar getirilemedi');
                }
            })
    }
    function _contentUpdate() {
        const { id } = secim;
        _multisliderGuncelle({
            baslik_tr: secim.baslik_tr,
            baslik_en: secim.baslik_en,
            baslik_ru: secim.baslik_ru,
            baslik_de: secim.baslik_de,
            baslik_cz: secim.baslik_cz,
            aciklama_tr: secim.aciklama_tr,
            aciklama_en: secim.aciklama_en,
            aciklama_ru: secim.aciklama_ru,
            aciklama_de: secim.aciklama_de,
            aciklama_cz: secim.aciklama_cz,
            image: secim.storefile ? secim.storefile[0].name : secim.image,
            link: secim.link,
            sira_no: secim.sira_no,
        }, id)
            .then(function (data) {
                if (secim.storefile) {
                    const formData = new FormData();
                    formData.append(`photo`, secim.storefile[0]);
                    _upload(formData)
                }

                if (!data.data.error) {
                    getir();
                } else {
                    toast.error('Hata oluştu');
                    setShow(false)
                }
                if (!data.data.error) {
                    toast.success('Multislider güncellendi');
                    setShow(false)
                    getir();
                }
            });

    }
    function _contentUpdateSliderItemClear() {
        setSecim(false)
    }
    function _handleSec(e) {
        let secimProp = { ...secim }
        let alan = `${e.target.name}`;
        if (alan === "storefile") {
            secimProp[alan] = e.target.files;
            console.log(e.target.files)
            setSecim(secimProp)
        } else {
            secimProp[alan] = e.target.value;
            setSecim(secimProp)
        }
    }
    function _clickEvent(e) {
        _multisliderDetay(e.id)
            .then((gelen) => {
                console.log(gelen.data)
                setSecim(gelen.data.data)
                showModal(e);
            });
    }
    function _contentSave() {
        _multisliderKaydet({
            baslik_tr: secim.baslik_tr,
            baslik_en: secim.baslik_en,
            baslik_ru: secim.baslik_ru,
            baslik_de: secim.baslik_de,
            baslik_cz: secim.baslik_cz,
            aciklama_tr: secim.aciklama_tr,
            aciklama_en: secim.aciklama_en,
            aciklama_ru: secim.aciklama_ru,
            aciklama_de: secim.aciklama_de,
            aciklama_cz: secim.aciklama_cz,
            image: secim.storefile[0].name,
            link: secim.link,
            sira_no: secim.sira_no,
        })
            .then(function (data) {
                const formData = new FormData();
                formData.append(`photo`, secim.storefile[0]);
                _upload(formData)
                if (!data.data.error) {
                    getir();
                } else {
                    toast.error('Hata oluştu');
                    setShow(false)
                }
            })

            setShow(false)
        toast.success('Multislider Oluşturuldu')

    }
    function _clickDel(x) {
        _multisliderSil(x)
            .then(cevap => {
                if (!cevap.data.error) {
                    getir();
                    toast.success('Silme işlemi başarılı')
                } else {
                    toast.error('Silinemedi')
                }
            })
    }
    const steps = [
        {
            name: 'Slide Başlık', component: <div className="inputs">
                <div className="editorName">
                    <input type="textbox" placeholder="Başlık:Türkçe" name="baslik_tr" value={secim.baslik_tr || ""} onChange={(e) => _handleSec(e)} />
                    <input type="textbox" placeholder="Başlık:İngilizce" name="baslik_en" value={secim.baslik_en || ""} onChange={(e) => _handleSec(e)} />
                    <input type="textbox" placeholder="Başlık:Rusça" name="baslik_ru" value={secim.baslik_ru || ""} onChange={(e) => _handleSec(e)} /> 
                    <input type="textbox" placeholder="Başlık:Almanca" name="baslik_de" value={secim.baslik_de || ""} onChange={(e) => _handleSec(e)} />
                    <input type="textbox" placeholder="Başlık:Çekçe" name="baslik_cz" value={secim.baslik_cz || ""} onChange={(e) => _handleSec(e)} />
                    <input
                        name="sira_no"
                        type="textbox"
                        placeholder="Sıra No"
                        value={secim.sira_no || ""}
                        onChange={(e) => _handleSec(e)}
                    />
                </div>
            </div>
        },
        {
            name: 'Açıklama', component: <div className="inputs">
                <div className="editorName">
                    <input type="textbox" placeholder="Açıklama:Türkçe" name="aciklama_tr" value={secim.aciklama_tr || ""} onChange={(e) => _handleSec(e)} />
                    <input type="textbox" placeholder="Açıklama:İngilizce" name="aciklama_en" value={secim.aciklama_en || ""} onChange={(e) => _handleSec(e)} />
                    <input type="textbox" placeholder="Açıklama:Rusça" name="aciklama_ru" value={secim.aciklama_ru || ""} onChange={(e) => _handleSec(e)} />
                    <input type="textbox" placeholder="Açıklama:Almanca" name="aciklama_de" value={secim.aciklama_de || ""} onChange={(e) => _handleSec(e)} />
                    <input type="textbox" placeholder="Açıklama:Çekçe" name="aciklama_cz" value={secim.aciklama_cz || ""} onChange={(e) => _handleSec(e)} />

                </div>
            </div>
        },
        {
            name: 'Yükleme', component: <div className="inputs">
                <GalleryItem imageList={secim.image} />
                <div className="editorName">
                    <input
                        type="file"
                        placeholder="Kamyanya ile alakalı resim"
                        name="storefile"
                        multiple={false}
                        onChange={(e) => _handleSec(e)} />
                    <input type="textbox" placeholder="Link ekleme" name="link" value={secim.link || ""} onChange={(e) => _handleSec(e)} />
                </div>
            </div>
        },
        {
            name: 'Bitir', component: <div className="editorName">
                <button disabled={(secim && !secim.id) ? false : true} onClick={() => _contentSave()}><IoMdSave />Kaydet</button>
                <button disabled={secim.id ? false : true} onClick={() => _contentUpdate()}><IoMdRefreshCircle />Güncelle</button>
                <button disabled={secim ? false : true} onClick={() => _contentUpdateSliderItemClear()}> <IoIosAdd />Yeni Oluştur</button>
            </div>
        }
    ]
    return <div className="tasiyici">
        <Modal onClose={showModal} show={show}>
            <Multistep showNavigation={true} steps={steps} />
        </Modal>
        <div className="sol">
            <div className="liste">
                {dataBaslik ? <Lister
                    filter={""}
                    listeBaslik={"Multi Slider"}
                    dataBaslik={dataBaslik}
                    showModalAndClear={showModalAndClear}
                    _clickDel={_clickDel}
                    _clickEvent={_clickEvent}
                    visual={"name"}
                /> : "Yükleniyor."}

            </div>
        </div>

    </div>
}


export default {
    routeProps: {
        path: '/multislider',
        component: MultiSlider,
    },
    name: 'Multi Slider',
    icon: <MdPictureInPictureAlt />,
    hideMenu: false,
};