import styled from 'styled-components';

export const SahaWrapper = styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
justify-content:flex-start;
flex-basis:50%;
/* padding:5px; */
box-sizing:border-box;
`
export const SahaUl = styled.ul`
margin:0px;
padding:0px;
display:flex;
flex-direction:row;
flex-wrap:wrap;
flex-basis:100%;
li{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    list-style:none;
    flex-basis:100%;
    padding:5px;
    box-sizing:border-box;
    align-content:center;
    span{
        flex-basis:25%;
        padding:0px 5px;
        height:56px;
        box-sizing:border-box;
        flex-shrink:1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:last-child{
            flex-shrink:2;
        }
    }
}
`