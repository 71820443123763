import React, { useEffect, useState } from 'react';
import { SahaWrapper, SahaUl } from './styles'
import DatePicker from 'react-datepicker';
import {FloatInput} from '../../../components/floatigLabelInput/styles'
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
export default (props) => {
    return <SahaWrapper>
        <SahaUl>
            {props.data ? props.data.map((x) => {
                return <li>
                    <select value={x.saha_id} readOnly={true} >
                        {props.sahalist.map((y) => {
                            return <option value={y.id}>{y.name}</option>
                        })}
                    </select>
                    {/* <span>{x.date}</span><span>{x.teetime}</span> */}
                    <DatePicker
                        dateFormat="dd.MM.yyyy"
                        name={x.date}
                        selected={x.date ? moment(x.date).toDate() : false}
                        locale="en-GB"
                        autoComplete="off"
                    customInput={<FloatInput />}
                    // onChange={(e) => props.dateChanger(e, "arrivaldate")}
                    /><span>{x.teetime}</span>
                </li>
            }) : false}
        </SahaUl>
    </SahaWrapper>
}