import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;

export const _paketlistesi = () => {
    return axios.get(API_URL + '/paket', { headers: authHeader() })
}
export const _hotellistesi = () => {
    return axios.get(API_URL + '/hotel', { headers: authHeader() })
}
export const _hotelDetay = (id) => {
    return axios.get(API_URL + `/hotel/${id}`, { headers: authHeader() })
}
export const _hotelSil = (id) => {
    return axios.delete(API_URL + `/hotel/${id}`, { headers: authHeader() })
}
export const _hotelKaydet = (data) => {

    return axios.post(API_URL + `/hotel`, { ...data },{ headers: authHeader() } )
}
export const _hotelGuncelle = (data,id) => {
    return axios.put(API_URL + `/hotel/${id}`, { ...data },{ headers: authHeader() } )
}