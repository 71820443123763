import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;

export const _sahalistesi = () => {
    return axios.get(API_URL + '/saha', { headers: authHeader() })
}
export const _paketlistesi = async () => {
    return await axios.get(API_URL + '/paket', { headers: authHeader() })
}
export const _hotellistesi = () => {
    return axios.get(API_URL + '/hotel', { headers: authHeader() })
}
export const _paketDetay = (id) => {
    return axios.get(API_URL + `/paket/${id}`, { headers: authHeader() })
}
export const _paketSil = (id) => {
    return axios.delete(API_URL + `/paket/${id}`, { headers: authHeader() })
}
export const _paketKaydet = (data) => {
    const params = {
        name: data.name,
        rounds: data.rounds,
        tr: data.tr,
        en: data.en,
        ru: data.ru,
        de: data.de,
        cz: data.cz,
        prices: data.prices,
        sahas: data.sahas,
        icerik: data.icerik,
        hotel_id: data.hotel_id,
        list_top: data.list_top,
        list_sira_no: data.list_sira_no
    }
    return axios.post(API_URL + `/paket`, { ...params }, { headers: authHeader() })
}
export const _paketdenSahaSil = (id) => {
    return axios.delete(API_URL + `/saha_and_paket/${id}`, { headers: authHeader() })
}
export const _paketdenPriceSil = (id) => {
    return axios.delete(API_URL + `/prices/${id}`, { headers: authHeader() })
}
export const _paketdenIcerikSil = (id) => {
    return axios.delete(API_URL + `/icerik/${id}`, { headers: authHeader() })
}
export const _paketGuncelle = (data,id) => {
    const params = {
        aciklama_id:data.aciklama_id,
        name: data.name,
        rounds: data.rounds,
        tr: data.tr,
        en: data.en,
        ru: data.ru,
        de: data.de,
        cz: data.cz,
        prices: data.prices,
        sahas: data.sahas,
        icerik: data.icerik,
        hotel_id: data.hotel_id,
        list_top: data.list_top,
        list_sira_no: data.list_sira_no
    }
    return axios.put(API_URL + `/paket/${id}`, { ...params }, { headers: authHeader() })
}