import React, { Fragment } from 'react'

export default (props) => {
    return <Fragment><input 
    type="text" 
    placeholder="İsim"
    onChange={(e)=> props.change(e)}/>
    {/* //TODO: diğer alanlardan bi kaç tane eklenicek */}
    {/* <input 
    type="text" 
    placeholder="İsim"
    onChange={(e)=> props.change(e)}/> */}
    </Fragment>
}