import React, { useState, useEffect } from 'react';
import { TeeTimeSC, AllWrap, Wrapper, Splitter } from './style'
import { toast } from 'react-toastify';
import { _sahalistesi,_paketdenSahaSil } from '../services'
export default (props) => {
    const [sahaList, setSahaList] = useState(false);
 
    function sil(x) {
        _paketdenSahaSil(x)
            .then(function (data) {
                if (!data.data.error) {
                    toast.success('Paketten saha Silindi')
                    // _listeGuncelle();
                    // _yeniSayfa();
                } else {
                     toast.error('Paketten saha silme başarısız.')
                }
            });
    }
    useEffect(() => {
        _sahalistesi().then((res) => {
            console.log(res)
            if (res.data.error) {
                toast.error('Sahalar getirileme');
            } else {
                setSahaList(res.data.data)
            }
        })
    }, [])
    return <TeeTimeSC>
        {props.values ? props.values.map((x, index) => {
            return <AllWrap key={index}>
                <Wrapper>
                    <Splitter>
                        <label>
                            <span>Golf Course #{index + 1}</span>
                            {console.log(x.id || x.saha_id ? x.saha_id || x.saha_and_paket.saha_id || false : false)}
                            <select value={x.id || x.saha_id ? x.saha_id || false : false} name={`saha_id`} onChange={(e) => props.change(e, index)}>
                                <option value={false}>Please Select</option>
                                {sahaList ? sahaList.map((y, i) => {
                                    return <option value={y.id} key={i}>{y.name}</option>
                                }) : false}
                            </select>
                        </label>
                    </Splitter>
                    <Splitter>
                        <label>
                            <span>İşlemler</span>
                            <button onClick={(e) => {
                                if (x.id) {
                                    sil(x.id)
                                    props.delete(e, index)
                                } else {
                                    props.delete(e, index)
                                }
                            }}>Sil</button>
                        </label>
                    </Splitter>
                </Wrapper>
            </AllWrap>
        }) : false}
    </TeeTimeSC>
}