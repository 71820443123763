import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import StepZilla from "react-stepzilla";
import htmlToDraft from 'html-to-draftjs';
import '../../styles/react-draft-wysiwyg.css'
import '../../styles/Draft.css'
import { toast } from 'react-toastify';
import ListViewer from './Liste';
import { FaQuestionCircle, FaBed } from 'react-icons/fa';
import { toolbarDefine } from '../../utils/editorToolbar'
import { initialStateCz, initialStateDe, initialStateEn, initialStateRu, initialStateTr } from './editorInitial/';
import { PlaceHolderTr, PlaceHolderCz, PlaceHolderDe, PlaceHolderEn, PlaceHolderRu } from './editorInitial/placeHolder'
import { _hotellistesi, _hotelSil, _hotelKaydet, _hotelDetay, _hotelGuncelle } from './services'
function HotelDetail(props) {
  const [editorStateTr, setEditorStateTr] = useState(initialStateTr);
  const [editorStateEn, setEditorStateEn] = useState(initialStateEn);
  const [editorStateRu, setEditorStateRu] = useState(initialStateRu);
  const [editorStateDe, setEditorStateDe] = useState(initialStateDe);
  const [editorStateCz, setEditorStateCz] = useState(initialStateCz);
  const [secim, setSecim] = useState(false);
  const [liste, setListe] = useState(false);
  useEffect(() => {
    _hotellistesi()
      .then((gelen) => {
        setListe(gelen.data.data)
      });
  }, [])
  function _yeniSayfa() {
    setSecim(false)
  }
  function _listeGuncelle() {
    _hotellistesi()
      .then((gelen) => {
        setListe(gelen.data.data)
      });
  }
  function _clickDel(x) {
    _hotelSil(x.id)
      .then(function (res) {
        if (!res.data.error) {
          toast.success('Hotel Silindi')
          _listeGuncelle();
          _yeniSayfa();
        } else {
          toast.error('Hotel silme başarısız.')
        }
      });
  }
  function _contentSave() {
    _hotelKaydet({
      name: secim.name,
      tr: draftToHtml(convertToRaw(editorStateTr.getCurrentContent())),
      en: draftToHtml(convertToRaw(editorStateEn.getCurrentContent())),
      ru: draftToHtml(convertToRaw(editorStateRu.getCurrentContent())),
      de: draftToHtml(convertToRaw(editorStateDe.getCurrentContent())),
      cz: draftToHtml(convertToRaw(editorStateCz.getCurrentContent())),
      map: secim.map,
      list_sira_no: secim.list_sira_no,
    })
      .then(function (data) {
        if (!data.data.error) {
          toast.success('Hotel eklendi')
          _listeGuncelle();
          _yeniSayfa();
        } else {
          toast.error('Hotel ekleme başarısız.')
        }
      });


  }
  function _stateConverter(data) {
    const blocksFromHtml = htmlToDraft(data);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  function _clickEvent(e) {
    _hotelDetay(e.id)
      .then((gelen) => {
        const editorStateTr = _stateConverter(gelen.data.data.aciklama[0].tr || PlaceHolderTr);
        const editorStateEn = _stateConverter(gelen.data.data.aciklama[0].en || PlaceHolderEn);
        const editorStateRu = _stateConverter(gelen.data.data.aciklama[0].ru || PlaceHolderRu);
        const editorStateDe = _stateConverter(gelen.data.data.aciklama[0].de || PlaceHolderDe);
        const editorStateCz = _stateConverter(gelen.data.data.aciklama[0].cz || PlaceHolderCz);
        setEditorStateTr(editorStateTr);
        setEditorStateEn(editorStateEn);
        setEditorStateRu(editorStateRu);
        setEditorStateDe(editorStateDe);
        setEditorStateCz(editorStateCz);
        setSecim({ ...gelen.data.data, aciklama_id: gelen.data.data.aciklama[0].id })

      });
  }
  function onEditorStateChangeEn(editorStateEn) {
    setEditorStateEn(editorStateEn);
  }
  function onEditorStateChangeTr(editorStateTr) {
    setEditorStateTr(editorStateTr);
  }
  function onEditorStateChangeRu(editorStateRu) {
    setEditorStateRu(editorStateRu);
  }
  function onEditorStateChangeDe(editorStateDe) {
    setEditorStateDe(editorStateDe);
  }
  function onEditorStateChangeCz(editorStateCz) {
    setEditorStateCz(editorStateCz);
  }
  function _inputChange(e) {
    let secimProp = { ...secim }
    let alan = `${e.target.name}`;
    secimProp[alan] = e.target.value;
    setSecim(secimProp);
  }
  function _contentUpdate() {
    const { id, name, map, aciklama_id, list_sira_no } = secim;
    _hotelGuncelle({
      aciklama_id: aciklama_id,
      name: name,
      tr: draftToHtml(convertToRaw(editorStateTr.getCurrentContent())),
      en: draftToHtml(convertToRaw(editorStateEn.getCurrentContent())),
      ru: draftToHtml(convertToRaw(editorStateRu.getCurrentContent())),
      de: draftToHtml(convertToRaw(editorStateDe.getCurrentContent())),
      cz: draftToHtml(convertToRaw(editorStateCz.getCurrentContent())),
      map: map,
      list_sira_no: list_sira_no,
    }, id)
      .then(function (data) {
        if (!data.data.error) {
          toast.success('Hotel Güncellendi')
          _listeGuncelle();
          _yeniSayfa();
        } else {
          toast.error('Hotel güncelleme başarısız.')
        }
      });

  }
  const steps = [
    {
      name: 'Ad', component: <div className="inputs"><div className="editorName">
        <label>Hotel Adı</label>
        <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
        <input type="text" required value={secim.name || ""} name="name" placeholder="e.g: Hotel Plus" onChange={(e) => _inputChange(e)} />
        <label>Sıra No</label>
        <span title="Frontend'de hotel listesindeki sıralamayı ayarlar"><FaQuestionCircle /></span>
        <input type="text" required value={secim.list_sira_no || ""} name="list_sira_no" placeholder="e.g: 1" onChange={(e) => _inputChange(e)} />
        <label>Konum</label>
        <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
        <input type="text" required value={secim.map || ""} name="map" placeholder="e.g: 35.5542" onChange={(e) => _inputChange(e)} />

      </div></div>
    },

    {
      name: 'İçerik', component: <div className="wysiwyg">
        <Editor
          editorState={editorStateEn}
          onEditorStateChange={onEditorStateChangeEn}
          toolbar={toolbarDefine}
        />
        <Editor
          editorState={editorStateRu}
          onEditorStateChange={onEditorStateChangeRu}
          toolbar={toolbarDefine}
        />
        <Editor
          editorState={editorStateDe}
          onEditorStateChange={onEditorStateChangeDe}
          toolbar={toolbarDefine}
        />
        <Editor
          editorState={editorStateTr}
          onEditorStateChange={onEditorStateChangeTr}
          toolbar={toolbarDefine}
        />
        <Editor
          editorState={editorStateCz}
          onEditorStateChange={onEditorStateChangeCz}
          toolbar={toolbarDefine}
        />
      </div>
    },
    {
      name: 'Bitir', component: <div className="inputs"> <div className="editorName">
        <button disabled={secim.id ? false : true} onClick={_contentUpdate}>Güncelle</button>
        <button disabled={(secim && !secim.id) ? false : true} onClick={_contentSave}>Kaydet</button>
        <button disabled={secim ? false : true} onClick={_yeniSayfa}>Temizle</button>
      </div></div>
    }
  ];
  return <div className="tasiyici">
    <div className="sol">
      <div className="liste">
        {liste ? <ListViewer
          title="Hoteller"
          liste={liste}
          ekle={_yeniSayfa}
          clickDel={_clickDel}
          clickEvent={_clickEvent}
        /> : <div className="loading">"Yükleniyor..."</div>}
      </div>
    </div>
    <div className="sag">
      <div className="baslik">
        <h4>Hotel İçeriği</h4>
      </div>
      <StepZilla
        steps={steps}
        preventEnterSubmission={true}
        nextButtonCls="ileri"
        backButtonCls='geri'
      />
    </div>
  </div>
}

export default {
  routeProps: {
    path: '/hotelv2',
    component: HotelDetail
  },
  name: 'Hotel',
  icon: <FaBed />,
  hideMenu: false,
}
