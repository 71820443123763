import React from 'react';
import { TableHeader,TableColumn } from './styles'
export default (props) => {
    return <TableHeader>
        {props.columnNames ? props.columnNames.sort(function(a,b) { return a.sira_no - b.sira_no }).map((x, i) => {
            return <span key={i} style={{ backgroundColor: x.color, color: x.text_color }}>
                <h4>{x.name}</h4>
                <TableColumn
                    key={i}
                    value={x.name}
                    type="textbox"
                    onChange={(e) => props.changeColumn(i, e.target.value)}
                    onBlur={(e) => props.changeRemoteColumn(e.target.value,x)}
                />
                <button onClick={() => props.deleteColumn(i,x)}>x</button>
            </span>
        }) : false}
    </TableHeader>
}