import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import modules from '../../modules';
import Logo from '../Logo'
import { UserContext } from '../../stores/userContextV2'
import { loginUser, logoutUser } from '../../actions/userActions'
import { HeaderSC } from './style'
export default function Header() {
  const { userState, userDispatch } = useContext(UserContext);

  return (<HeaderSC>
    <Logo />
    <ul>
      {modules.map(module => (
        module.headerLocale ? <li key={module.name}>
          <Link to={module.routeProps.path}>{module.name}</Link>
        </li> : false
      ))}
      {userState ? <button
        onClick={() => {
          localStorage.removeItem("user");
          userDispatch(logoutUser())
        }}>Oturum Kapat</button> : <button onClick={() => {
          userDispatch(loginUser())
        }}>Oturum Aç</button>}
    </ul>
  </HeaderSC>
  )
}