import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;
export const _images=(id)=>{
    return axios.get(API_URL + `/image/gallery/${id}`, { headers: authHeader() })
}
export const _silResim = (id) => {
    return axios.delete(API_URL + `/image/${id}`, { headers: authHeader() })
}
export const _analyticslistesi = () => {
    return axios.get(API_URL + '/analytics', { headers: authHeader() })
}
export const _hotellistesi = () => {
    return axios.get(API_URL + '/hotel', { headers: authHeader() })
}
export const _sahalistesi = () => {
    return axios.get(API_URL + '/saha', { headers: authHeader() })
}
export const _analyticsDetay = (id) => {
    return axios.get(API_URL + `/analytics/${id}`, { headers: authHeader() })
}
export const _analyticsSil = (id) => {
    return axios.delete(API_URL + `/analytics/${id}`, { headers: authHeader() })
}
export const _analyticsKaydet = (data) => {
    const params = {
        name: data.name,
        trackedvalue: data.trackedvalue,
    }
    return axios.post(API_URL + `/analytics`, { ...params }, { headers: authHeader() })
}
export const _upload = (data, cb) => {
    return axios.post(API_URL + `/uploads`, data, {
        headers: authHeader(), onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            cb(percentCompleted)
        }
    })
}
export const _analyticsGuncelle = (data, id) => {
    return axios.put(API_URL + `/analytics/${id}`, { ...data }, { headers: authHeader() })
}