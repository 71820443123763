import axios from "axios";
import { UrlContext } from '../../stores/url'
const API_URL = UrlContext._currentValue;

const _login = (username, password) => {
    const params = {
        user_name: username,
        password: password,
    }
    return axios.post(API_URL + '/login', params)
}

export default {
    _login
};