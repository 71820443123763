import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoIosAdd, IoMdRefreshCircle, IoMdSave, IoMdAnalytics } from "react-icons/io";
import { toast } from 'react-toastify';
import Modal from "../../components/Modal";
import List from './list'
import { Sag } from './styles.js'
import { _analyticslistesi, _analyticsDetay,_analyticsGuncelle, _analyticsKaydet,_analyticsSil } from './services'

const Analytics = function (props) {
    const [secim, setSecim] = useState(false);
    const [dataBaslik, setDataBaslik] = useState(false);
    const [show, setShow] = useState(false);
    useEffect(() => {
        _analyticslistesi()
            .then(list => {
                setDataBaslik(list.data.data)
            })
    }, [])
    function showModal(e) {
        setShow(!show)
    };
    function showModalAndClear(e) {
        setShow(!show)
        setSecim(false)
    };
    function getir() {
        _analyticslistesi()
            .then(list => {
                setDataBaslik(list.data.data)
            })
    }
    function _contentUpdateSliderItem() {

        const { id, name, trackedvalue } = secim;
        _analyticsGuncelle({name, trackedvalue},id)
            .then(function (data) {
                if (!data.data.error) {
                    toast.success('Güncelleme başarılı.')
                    getir();
                } else {
                    toast.error('Güncelleme yapılamadı.')
                }
            });

    }
    function _contentUpdateSliderItemClear() {
        setSecim(false)
    }
    function _handleSec(e) {
        let secimProp = { ...secim }
        let alan = `${e.target.name}`;
        secimProp[alan] = e.target.value;
        setSecim(secimProp)
    }
    function _clickEvent(e) {
        _analyticsDetay(e.id)
            .then((gelen) => {
                console.log(gelen)
                setSecim(gelen.data.data)
                showModal(e);
            });
    }
    function _contentSave() {
        _analyticsKaydet({
            name: secim.name,
            trackedvalue: secim.trackedvalue,
        })
            .then(function (data) {
                if (!data.data.error) {
                    toast.success('Kaydetme başarılı.')
                    getir();
                } else {
                    toast.error('Kaydetme yapılamadı.')
                }
            });


    }
    function _clickDel(x) {
        _analyticsSil(x)
            .then(function (data) {
                if (!data.error) {
                    toast.success('Silme başarılı.')
                    getir();
                } else {
                    toast.error('Silme yapılamadı.')
                }
            });
    }
    return <div className="tasiyici">
        <Modal onClose={showModal} baslik="Analytics Ekle/Düzenle" show={show}>
            <div className="inputs">
                <div className="editorName">
                    <input type="textbox" placeholder="Sağlayıcı Adı Örn:Google" name="name" value={secim.name || ""} onChange={(e) => _handleSec(e)} />
                    <input type="textbox" placeholder="Takip Kodu Örn:UA-00000000-00" name="trackedvalue" value={secim.trackedvalue || ""} onChange={(e) => _handleSec(e)} />
                </div>
                <div className="editorName">
                    <button className="buttons" disabled={(secim && !secim.id) ? false : true} onClick={() => _contentSave()}><IoMdSave />Kaydet</button>
                    <button className="buttons" disabled={secim.id ? false : true} onClick={() => _contentUpdateSliderItem()}><IoMdRefreshCircle />Güncelle</button>
                    <button className="buttons" disabled={secim ? false : true} onClick={() => _contentUpdateSliderItemClear()}> <IoIosAdd />Yeni Oluştur</button>
                </div>
            </div>
        </Modal>
        <div className="sol">
            <List
                baslik={"Analiz Sağlayıcıları"}
                ekle={showModalAndClear}
                sil={_clickDel}
                edit={_clickEvent}
                data={dataBaslik}
            />
        </div>
        <Sag>
            <p>Site ziyaretçi sayısı, oturum süresi vb. hizmetleri sağlayan teknoloji şirketlerinden alınan takip kodlarının yönetildiği bölümdesiniz.
         <i>"Ekle"</i> butonundan yeni bir sağlayıcı ekleyerek sağlayıcının sitenize erişip veri toplamasını sağlayabilirsiniz.</p>
            <h5>Örneğin:</h5>
            <ul>
                <li>Google Analytics</li>
                <li>Yandex Metrica</li>
            </ul>
        </Sag>
    </div>
}
Analytics.propTypes = {
    apiUrl: PropTypes.string.isRequired
};

export default {
    routeProps: {
        path: '/analytics',
        component: Analytics
    },
    name: 'Analytics',
    icon: <IoMdAnalytics />,
    hideMenu: false,
}