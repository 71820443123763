import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;
export const _images=(id)=>{
    return axios.get(API_URL + `/image/gallery/${id}`, { headers: authHeader() })
}
export const _imagesSetCover=(data,id)=>{
    return axios.put(API_URL + `/image/${id}`,data, { headers: authHeader() })
}
export const _silResim = (id) => {
    return axios.delete(API_URL + `/image/${id}`, { headers: authHeader() })
}
export const _gallerylistesi = () => {
    return axios.get(API_URL + '/gallery', { headers: authHeader() })
}
export const _hotellistesi = () => {
    return axios.get(API_URL + '/hotel', { headers: authHeader() })
}
export const _sahalistesi = () => {
    return axios.get(API_URL + '/saha', { headers: authHeader() })
}
export const _galleryDetay = (id) => {
    return axios.get(API_URL + `/gallery/${id}`, { headers: authHeader() })
}
export const _gallerySil = (id) => {
    return axios.delete(API_URL + `/gallery/${id}`, { headers: authHeader() })
}
export const _galleryKaydet = (data) => {
    const params = {
        name: data.name,
        checked: data.checked,
        outid: data.outid
    }
    return axios.post(API_URL + `/gallery`, { ...params }, { headers: authHeader() })
}
export const _upload = (data, cb) => {
    return axios.post(API_URL + `/uploads`, data, {
        headers: authHeader(), onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            cb(percentCompleted)
        }
    })
}
export const _paketdenSahaSil = (id) => {
    return axios.delete(API_URL + `/saha_and_paket/${id}`, { headers: authHeader() })
}
export const _paketdenPriceSil = (id) => {
    return axios.delete(API_URL + `/prices/${id}`, { headers: authHeader() })
}
export const _paketdenIcerikSil = (id) => {
    return axios.delete(API_URL + `/icerik/${id}`, { headers: authHeader() })
}
export const _galleryGuncelle = (data, id) => {
    return axios.put(API_URL + `/gallery/${id}`, { ...data }, { headers: authHeader() })
}