import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from './routes';
import Header from './components/Header'
import { UserContext } from './stores/userContextV2'
import LeftMenu from './components/LeftMenu/'
import PTFPN from './utils/pageTitleForProjectName';
import './styles/style.scss';
import Store from './stores/store';
import { MainboardSC, ContentSC, WrapperSC } from './style';
import LoginComponent from './components/login'
import checkSession from './utils/checkSession'
function App() {
  const { userState, userDispatch } = useContext(UserContext);
  useEffect(() => { PTFPN(); }); //sayfa başlığını package.json daki proje ismi sürüm adı ve version değerleri ile değiştiriyor.
  // useEffect(() => {
    
   checkSession(userDispatch) // token exp. date kontrolü yapıp exp olmuşsa  logine atıyor
  // },[userState,userDispatch]);
  return (
    <Store>
      <Router>
        {userState ? <WrapperSC>
          <LeftMenu />
          <ContentSC>
            <Header />
            <MainboardSC>
              <Routes />
            </MainboardSC>
          </ContentSC>
        </WrapperSC> : <LoginComponent />
        }
      </Router>
    </Store>
  )
}
export default App;
