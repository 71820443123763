import React, { useState } from 'react';
import { FloatDiv, FloatInput, FloatSelect, FloatCheckBox, FloatLabel } from './styles'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
export default (props) => {
    // const [value, setValue] = useState('');
    const [isActive, setIsActive] = useState(props.value ? true : false);
    function _fallFocus() {
        if (props.value !== '') {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }
    if(props.type ==="date"){
        console.log(props.value)
        console.log(new Date(props.value))
        console.log(moment(props.value).toDate())
    }
    return <FloatDiv>
        {props.type === "select" &&
            <FloatSelect
                name={props.name}
                value={props.value}
                onFocus={() => setIsActive(true)}
                onBlur={() => _fallFocus()}
                onChange={(e) => props.change(e)}
            >
                <option value="null"></option>
                {props.list ? props.list.map((y, i) => {
                    return <option key={i} value={y.id}>{y.name}</option>
                }) : false}
            </FloatSelect>
        }
        {props.type === "checkbox" && <FloatCheckBox
            value={props.value}
            checked={props.value}
            type={props.type}
            name={props.name}
            onFocus={() => setIsActive(true)}
            onBlur={() => _fallFocus()}
            onChange={(e) => props.change(e)}
        />}
        {props.type === "textbox" && <FloatInput
            value={props.value}
            type={props.type}
            name={props.name}
            disabled={props.disabled}
            onFocus={() => setIsActive(true)}
            onBlur={() => _fallFocus()}
            onChange={(e) => props.change(e)}
        />}
        {props.type === "email" && <FloatInput
            value={props.value}
            type={props.type}
            name={props.name}
            onFocus={() => setIsActive(true)}
            onBlur={() => _fallFocus()}
            onChange={(e) => props.change(e)}
        />}
        {props.type === "date" &&
            //  <FloatInput
            //     value={props.value}
            //     type={props.type}
            //     locale="en-GB"
            //     name={props.name}
            //     onFocus={() => setIsActive(true)}
            //     onBlur={() => _fallFocus()}
            //     onChange={(e) => props.change(e)}
            // />
            <DatePicker
                 dateFormat="dd.MM.yyyy"
                name={props.name}
                selected={props.value?moment(props.value).toDate(): false}
                locale="en-GB"
                autoComplete="off"
                onFocus={() => setIsActive(true)}
                onBlur={() => _fallFocus()}
                onChange={(e) => props.change(e,props.name)}
                customInput={<FloatInput/>}
            // onChange={(e) => props.dateChanger(e, "arrivaldate")}
            />
        }

        <FloatLabel
            active={isActive}
        >
            {props.placeholder}
        </FloatLabel>
    </FloatDiv>
}