import React from 'react';
import { FaProjectDiagram } from 'react-icons/fa';


function Test(){
    return (
      
       <div>
            <div className="sol">
                <div className="liste">
              
                   
                </div>
            </div>
            <div className="sag">
                
            </div>
        </div>
            
     
        
    )
}

export default {
    routeProps: {
        path: '/test',
        component: Test,
    },
    name: 'Test',
    icon:<FaProjectDiagram/>,
    headerLocale:true,
    hideMenu:true,
};