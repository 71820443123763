import React, { useState } from 'react';
import { IoIosAdd } from "react-icons/io";
import { ButtonSC, Listviewer, ListviewerTop, ListviewerUl, ButtonWrapper } from './styles'
import { MdDeleteForever, MdModeEdit } from "react-icons/md";
import Filtre from '../filtre'
const ListeView = (props) => {
    const [textValue, setTextValue] = useState("");
    const [selectValue, setSelectValue] = useState(false);
    const { liste, clickEvent, clickDel, title, ekle } = props;
    const [data, setListe] = useState(liste)
    function change(x) {
        setSelectValue(x)
    }
    function filterItems(arr, query) {
        return arr.filter(function (el) {
            return el.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        })
    }
    function filterHotel(arr, query) {
        return arr.filter(function (el) {
            return el.hotel[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        })
    }
    function changeText(x) {
        setTextValue(x)
        if (selectValue === "round") {
            if (x === "") {
                return setListe(liste);
            }
            const veri = liste.filter((a) => a.rounds === x ? a : false)
            console.log(veri)
            setListe(veri);
        } else if (selectValue === "paketname") {
            if (x === "") {
                return setListe(liste);
            }
            const veri = filterItems(liste, x)
            console.log(veri)
            setListe(veri);
        } else if (selectValue === "hotel") {
            if (x === "") {
                return setListe(liste);
            }
            const veri = filterHotel(liste, x)
            console.log(veri)
            setListe(veri);
        }

    }
    return (
        <Listviewer>
            <ListviewerTop>

                <h3>{title}</h3>
                <ButtonSC name="ekle" onClick={(e) => ekle(e)}>
                    <IoIosAdd /> Ekle</ButtonSC>
                <Filtre
                    textValue={textValue}
                    selectValue={selectValue}
                    change={change}
                    changeText={changeText}
                />
            </ListviewerTop>
            {data ? <ListviewerUl>
                {data.map(function (x, i) {

                    return <li key={i}>
                        <span>{x.name}</span>
                        <ButtonWrapper>
                            <ButtonSC onClick={() => clickDel(x)}>
                                <MdDeleteForever />
                            </ButtonSC>
                            <ButtonSC onClick={() => clickEvent(x)}>
                                <MdModeEdit />
                            </ButtonSC>
                        </ButtonWrapper>
                    </li>

                })}
            </ListviewerUl>
                : "Veri Yok"}

        </Listviewer>
    )
}
export default ListeView;
