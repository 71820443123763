import React, { useEffect,useContext } from 'react';
import modules from './modules';
import { Switch, Route,useHistory  } from "react-router-dom";
import { UrlContext } from './stores/url'
import checkSession from './utils/checkSession'
import { UserContext } from './stores/userContextV2'
const apiUrl = UrlContext._currentValue;
export default function Routes() {
    const { userState, userDispatch } = useContext(UserContext);
    let history = useHistory();
    console.log(history)
    useEffect(()=>{
        checkSession(userDispatch)
    },[history.location])
    return (
        <Switch>
            {modules.map(module => (
                <Route {...module.routeProps} key={module.name}>
                    <module.routeProps.component apiUrl={apiUrl} />
                </Route>
            ))}
        </Switch>
    )
}