import React from 'react';

export default (props) => {

    function Randomizer() {
        return Math.floor(Math.random() * 999999999999999)
    }
    return props.scorecardlist ? <div key={Randomizer()}>
        <p>Score Card Listesi</p>
        <ul>{props.scorecardlist.map((x, f) => {
            return <li key={Randomizer()}>
                {x.name || x.tablename}
                <button onClick={(e) => props.edit(x.id)}>
                    Edit
                </button>
                <button onClick={(e) => props.sil(x.id)}>
                    Sil
                </button>
            </li>
        })}</ul>
    </div> : "Liste Boş";
}