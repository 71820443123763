import React from 'react';
import PropTypes from 'prop-types';
import { IoIosAdd } from "react-icons/io";
import { ButtonSC, Listviewer, ListviewerTop, ListviewerUl, ButtonWrapper } from './styles'
import { MdDeleteForever, MdModeEdit } from "react-icons/md";
import Filter from './filter'
export default function List(props) {
    return <Listviewer>
        <ListviewerTop>
            <h3>{props.baslik}</h3>
            <Filter change={props.change}/>
            <ButtonSC name="ekle" onClick={(e) => props.ekle(e)}>
                <IoIosAdd /> Ekle</ButtonSC>
        </ListviewerTop>
        <ListviewerUl>
            {props.data ? props.data.length > 0 ? props.data.map((x, i) => {
                if (x) {
                    return <li key={i} >
                        <span>Id: {x.id} | İsim: {x.name} | Soyisim: {x.surname} | Telefon: {x.phone} | Mail: {x.email} | Golfer: {x.golfer} | Non-Golfer: {x.nongolfer}</span>
                        <ButtonWrapper>
                            <ButtonSC onClick={() => props.sil(x.id)}>
                                <MdDeleteForever />
                            </ButtonSC>
                            <ButtonSC onClick={() => props.edit(x)}>
                                <MdModeEdit />
                            </ButtonSC>
                        </ButtonWrapper>
                    </li>
                } else {
                    return false
                }
            }) : false : false}

        </ListviewerUl>
    </Listviewer>

}
List.propTypes = {
    baslik: PropTypes.string.isRequired,
    ekle: PropTypes.func.isRequired,
    sil: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    data: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.array
    ]),
};