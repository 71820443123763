import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import modules from '../../modules';
import { LeftSide } from './style'

export default function LeftMenu() {
  const [menu, setMenu] = useState(false)
  return (<LeftSide style={menu ? { flexGrow: 1 } : { flexGrow: 0 }}>
    <div className="menu-button" onClick={() => setMenu(!menu)}>
      <FiMenu />
    </div>
    <div>
      <ul>
        {modules.map(module => (
          module.headerLocale || module.hideMenu ? false : <li key={module.name}>
            <Link to={module.routeProps.path}>{module.icon}</Link>
          </li>
        ))}
      </ul>
      <ul style={menu ? { display: 'block' } : { display: 'none' }}>
        {modules.map(module => (
          module.headerLocale || module.hideMenu ? false : <li key={module.name}>
            <Link to={module.routeProps.path}>{module.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  </LeftSide>)
}