import React from 'react';
import ListButtons from './listButtons';

export default function (props) {
    return (<div className="listviewer">
        <h3>{props.listeBaslik}</h3>
        <button name="ekle" onClick={(e) => props.showModalAndClear(e)}>Ekle</button>

        <ul>
            {props.dataBaslik ? props.dataBaslik.length > 0 ? props.dataBaslik.map((x, i) => {
                if(x.tur){
                    if (x.tur === props.filter) {
                        return <li key={i}>{props.visual==="name"?<span>{x.baslik_en}</span>:<span>{x.en}</span>}<ListButtons {...props} x={x}/></li>
                    }else{
                        return false
                    }
                }else{
                    return <li key={i}>{props.visual==="name"?<span>{x.baslik_en}</span>:<span>{x.en}</span>}<ListButtons {...props} x={x}/></li>
                }
                
            }) : false : false}
        </ul>

    </div>)
}