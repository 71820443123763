import React, { useEffect, useState } from 'react';
import { Wrapper, UL, Li } from './styles'
import { _images, _silResim } from '../services'
import path from 'path'
export default (props) => {
    console.log(props.data)
    return props.data ? <Wrapper>
        {props.data && "Yeni Olanlar"}
        <UL>{Object.entries(props.data).map((x, y) => {
            let images = URL.createObjectURL(x[1])
            console.log(x)
            console.log(path.basename(x[1].name, path.extname(x[1].name)));
            if (x[1].sira_no === undefined) {
                props.setSira(x[0], parseInt(path.basename(x[1].name, path.extname(x[1].name))))
            }
            return <Li key={y}>
                <img
                    src={images}
                    alt={images} />
                <span>{x[1].name}
                    <input type="textbox"
                        placeholder="Sırası"
                        defaultValue={parseInt(path.basename(x[1].name, path.extname(x[1].name))) || null}
                        onChange={(e) => props.setSira(x[0], e.target.value)} />

                    <input type="checkbox" onClick={(e) => props.setCover(x[0])} />
                    <button onClick={(e) => props.silResim(x[0])}>Sil</button></span>
            </Li>
        })}
        </UL></Wrapper> : false
}