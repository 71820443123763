// import React, { useContext } from 'react';
import { UrlContext } from '../../../stores/url'
import {_post} from '../services'
const ApiUrl = UrlContext;
const host = process.env.NODE_ENV === "production" ? ApiUrl._currentValue+"/" : ApiUrl._currentValue+"/";

function post(body) {
  // return fetch(`${host}${path}`, {
  //   credentials: "omit",
  //   headers: { "content-type": "application/json;charset=UTF-8", "sec-fetch-mode": "cors" },
  //   body: JSON.stringify(body),
  //   method: "POST",
  //   mode: "cors"
  // })
  //   .then(function(response) {
  //     return response.json();
  //   })
  return _post(body)
    .then(function(data) {
      console.log(data)
      return data;
    });
}

function get(path) {
  console.log(host)
  return fetch(`${host}${path}`, {
    credentials: "omit",
    headers: { "content-type": "application/json;charset=UTF-8", "sec-fetch-mode": "cors" },
    method: "GET",
    mode: "cors"
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return data;
    });
}

const http = {
  post: post,
  get: get
};

export default http;