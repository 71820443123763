import { UrlContext } from '../stores/url'
const apiUrl = UrlContext._currentValue;
export const toolbarDefine = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
    image: {
      urlEnabled: true,
      uploadEnabled: true,
      uploadCallback: uploadImageCallBack,
      alt: { present: true, mandatory: true },
      previewImage: true
    },
    fontFamily:{
      options:["Poppins","Raleway","Arial","Georgia","Impact","Tahoma",'Times New Roman','Verdana']
    },
    fontSize:{
      options:['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30']
    }
  };
  function uploadImageCallBack(file) {
    var data = new FormData()
    data.append('paketimage', file)
  
    return new Promise(
      (resolve, reject) => {
        fetch(apiUrl+'/uploadeditorimage', { method: 'POST',body: data })
        .then(response => response.json())
        .then(veri => {resolve(veri)})
        .catch(error => console.log(error));
      }
    );
  }