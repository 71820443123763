import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;

export const _sahalistesi = () => {
    return axios.get(API_URL + '/saha', { headers: authHeader() })
}
export const _hotellistesi = () => {
    return axios.get(API_URL + '/hotel', { headers: authHeader() })
}
export const _sahaDetay = (id) => {
    return axios.get(API_URL + `/saha/${id}`, { headers: authHeader() })
}
export const _sahaSil = (id) => {
    return axios.delete(API_URL + `/saha/${id}`, { headers: authHeader() })
}
export const _sahaKaydet = (data) => {
    return axios.post(API_URL + `/saha`, { ...data },{ headers: authHeader() } )
}
export const _sahaGuncelle = (data,id) => {
    return axios.put(API_URL + `/saha/${id}`, { ...data },{ headers: authHeader() } )
}
export const _scorecardKaydet = (data) => {
    const params = {
        name: data.name,
        saha_id: data.saha_id,
        columnNames: data.columnNames
    }
    return axios.post(API_URL + `/scorecard`, { ...params }, { headers: authHeader() })
}