import styled from 'styled-components'

export const FiltreWrap = styled.div`
flex-basis:100%;
display:flex;
flex-direction:row;
justify-content:space-between;
padding:10px 0px 0px 0px;
box-sizing:border-box;
input{
    padding:5px;
    box-sizing:border-box;
}
`