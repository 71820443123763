import styled from 'styled-components'
export const TableBody = styled.ul`
flex-basis:100%;
 display:flex;
 flex-direction:row;
 justify-content:flex-start;
 background:white;
 margin:0;
 padding:0;
 li{
    list-style:none;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    flex:1;
    span,input{
        padding:10px;
        color:black;
        box-sizing:border-box;
        width:100%;
    }
 }
`