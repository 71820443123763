import styled from 'styled-components'

export const LeftSide = styled.div`
    top: 0;
    height: 100vh;
    background: #06a287;
    overflow: hidden;
    position: -webkit-sticky;
    position: sticky;
    transition: flex-grow 0.3s ease-in-out;
    padding: 0px 10px;
    color: #f5f6f8;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: flex-start;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    transition: 0.3s ease;
    border-radius: 0px 100px 0px 0px;
    padding-top: 25px;
    &:hover{
      transition: 500ms;
      -webkit-box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
      box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
    }
    ul {
      float: left;
      margin-top:0px;
      padding: 3px;
      transition-property: display;
      transition-duration: 5s;
      transition-delay: 5s;
      list-style: none;
      li {
        color: #f5f6f8;
        width: 100%;
        margin: 20px 0px;
        display: flex;
      }
      a {
        color: white;
        font-size: 14px;
        padding: 5px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.123);
        height: 20px;
        transition: 300ms;
        &:hover{
          transition: 300ms;
          color:rgb(6, 162, 135);
          box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.384);
          background-color: #f5f6f8;
        }
        img {
          width: 20px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
        span {
          float: right;
          //opacity: 0;
          transition-property: display;
          transition-duration: 5s;
          transition-delay: 2s;
        }
      }
      &:last-child {
        li {
          display: flex;
          animation: showNav 1s ease-in-out both;
          margin-left: 15px;
          a {
            text-decoration: none;
            transition: 300ms;
            &:hover {
              background: #f5f6f8;
              color: rgb(6, 162, 135);
              transition: 300ms;
            }
          }
        }
        &:active {
          li {
            opacity: 1;
            //display: block;
          }
        }
      }
    }
    .menu-button {
      color: white;
      width: 100%;
      box-sizing: border-box;
      padding-top: 10px;
      padding-left: 3px;
      cursor: pointer;
      img {
        width: 30px;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: 'Georgia', serif;
    font-size: 14px;
    padding: 15px;
  }
  
  .RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }
  
  .RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
  }
  
  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }
  
  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }
  
  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }
  
  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
  }
  
  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }
  
  .RichEditor-activeButton {
    color: #5890ff;
  }
    @keyframes showNav {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
