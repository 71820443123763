import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;
export const _taleplistesi = () => {
    return axios.get(API_URL + '/talep', { headers: authHeader() })
}
export const _paketlistesi = () => {
    return axios.get(API_URL + '/paket', { headers: authHeader() })
}
export const _hotellistesi = () => {
    return axios.get(API_URL + '/hotel', { headers: authHeader() })
}
export const _sahalistesi = () => {
    return axios.get(API_URL + '/saha', { headers: authHeader() })
}
export const _talepDetay = (id) => {
    return axios.get(API_URL + `/talep/${id}`, { headers: authHeader() })
}
export const _talepSil = (id) => {
    return axios.delete(API_URL + `/talep/${id}`, { headers: authHeader() })
}
export const _talepKaydet = (data) => {
    return axios.post(API_URL + `/talep`, { ...data }, { headers: authHeader() })
}
export const _talepGuncelle = (data, id) => {
    return axios.put(API_URL + `/talep/${id}`, { ...data }, { headers: authHeader() })
}