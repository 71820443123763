import React from 'react';
import PropTypes from 'prop-types';
import { MdDeleteForever, MdModeEdit } from "react-icons/md";
export default function List(props) {
    return <div className="liste">
        <div className="listviewer">
            <h3>{props.baslik}</h3>
            <button name="ekle" onClick={(e) => props.ekle(e)}>Ekle</button>

            <ul>
                {props.data ? props.data.length > 0 ? props.data.map((x, i) => {
                    if (x) {
                        return <li key={i} >
                            <span>{x.name}</span>
                            <button className="listbutton" onClick={() => props.sil(x.id)}><MdDeleteForever /></button>
                            <button className="listbutton" onClick={() => props.edit(x)}><MdModeEdit /></button>
                        </li>
                    }else{
                        return false
                    }
                }) : false : false}

            </ul>

        </div>
    </div>
}
List.propTypes = {
    baslik: PropTypes.string.isRequired,
    ekle:PropTypes.func.isRequired,
    sil:PropTypes.func.isRequired,
    edit:PropTypes.func.isRequired,
    data: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.array
      ]),
  };